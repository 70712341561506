
/*
	Avast Mixin for simplifying definition if various background image for Akamai Image Manager.

	Author: Čeněk Hybler

	Mixin parameters:
	$bg-image-path - directory and image name of your background image. E.g.: 'mydirectory/mybg@2x.jpg'

	Image parameters for IM:
	fcwidth, fcheight: feature crop - size in px; is calculated from original image (keep in mind that original images should be double sized for retina)
	width: resize - desired width after image is cropped
*/

@mixin aim-bg-image($bg-image-path) {
	background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=576&fcwidth=1152");

	@include media-breakpoint-up(sm) {
		background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=576&fcwidth=1152&fcheight=2000");
	}
	@include media-breakpoint-up(md) {
		background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=768&fcwidth=1536&fcheight=2000");
	}
	@include media-breakpoint-up(lg) {
		background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=992&fcwidth=1984&fcheight=2000");
	}

	@media (min-width: 1280px) {
		background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=1280&fcwidth=2560&fcheight=2000");
	}
	@media (min-width: 1366px) {
		background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=1366&fcwidth=2732&fcheight=2000");
	}

	@include media-breakpoint-up(xl) {
		background-image: url("../../../../i/v2/aim/bg/#{$bg-image-path}?width=1600");
	}
}
