/*
* Base unit for vertical rhytm.
* To be used primary for line heights (to keep typografy aligned with vertical rhytm grid) top and bottom margins and paddings heights.
*/
$font-size-base: 1rem;
$base-unit: $font-size-base !default;

/*
COLORS
=================
*/

/*
* Basic color pallete as simple variables
*/
$black: #000; // (Black)
$blue-dark: #071d2b; // (Dark Blue) - main text color, main bg color
$white: #fff; // (White)

$orange-bright: #ff7800; // (Bright Orange)
$orange-light: #ff9a40; // (Light Orange)
$orange-pale: #ffddbf; // (Pale Orange)
$orange-faint: #fff1e5; // (Faint Orange)
$orange-off-white: #fff9f5; // (Off-White Orange)

$blue-bright: #0070f6; // (Bright Blue)
$blue-bright-hover: #0069e6; // (Button hover)
$blue-bright-focus: #0061d4; // (Button focus)
$blue-light: #4096FF; // (Light Blue)
$blue-pale: #D4E7FF; // (Pale Blue)
$blue-faint: #f0f7ff; // (Faint Blue)
$blue-mid: #21455c; // (Mid Blue) badge color, elevation bg
$blue-mid-mid: #345970; // (Mid Mid Blue) inverse border color, smb cell bg
$blue-deep: #143347; // (Deep Blue) forms
$blue-deeper: #0c2636; // (Deeper Blue) elevation styles

$green-bright: #9ae437; // (Bright Green)
$green-bright-hover: #79db17; // (Button hover)
$green-bright-focus: #6ad004; // (Button focus)
$green-pale: #D5F2A5; // (Pale Green)
$green-faint: #effbdf; // (Faint Green)

$red: #d93511; // (Red)
$red-inverse: #FF5833; // (Red Inverse)
$yellow: #FFBF00; // (Yellow)
$yellow-inverse: #FFD900; // (Yellow Inverse)

// Status color
$status-ok: $green-bright;
$status-attention: $yellow;
$status-critical: $red;


//
//	🗺 z-index map
//
$z-index: (
	ds-nav__toggle:      1013,	// has to be higher than <ds-nav> z-index – it fixes visual overlay bug
	ds-header:           1012,
	ds-nav:              1012,
	cmp-message-bar:     1012,	// prevents <cmp-message-bar> being overlaped by <ds-backdrop>
	cmp-region-selector: 1012,
	cmp-sticky-bar:      1011,
	cmp-popover:         1010,
	ds-backdrop:         1009
);


// Maps of colors (used in e.g. bg color assignment or carousel gradients)
$colors-map: (
	"black": $black,
	"white": $white,
	"blue-dark": $blue-dark,
	"blue-deeper": $blue-deeper,
	"blue-deep": $blue-deep,
	"blue-mid": $blue-mid,
	"blue-mid-mid": $blue-mid-mid,
	"blue-bright": $blue-bright,
	"blue-light": $blue-light,
	"blue-pale": $blue-pale,
	"blue-faint": $blue-faint,
	"orange-bright": $orange-bright,
	"orange-light": $orange-light,
	"orange-pale": $orange-pale,
	"orange-faint": $orange-faint,
	"orange-off-white": $orange-off-white,
	"green-bright": $green-bright,
	"green-pale": $green-pale,
	"green-faint": $green-faint,
	"red": $red,
	"red-inverse": $red-inverse,
	"yellow": $yellow,
	"yellow-inverse": $yellow-inverse,
	"status-critical": $status-critical,
	"status-attention": $status-attention,
	"status-ok": $status-ok
);

// Shades of grey
$gray10: #f8f9fa; // (Gray 10)
$gray09: #eff1f2; // (Gray 9)
$gray08: #e5e8ea; // (Gray 8) - mostly borders
$gray07: #cfd5d9; // (Gray 7)
$gray06: #b8c1c7; // (Gray 6) - secondary texts on Dark Blue
$gray05: #94a2aa; // (Gray 5)
$gray04: #7d8d96; // (Gray 4)
$gray03: #677983; // (Gray 3) - secondary texts on White
$gray02: #4b5860; // (Gray 2)
$gray01: #394349; // (Gray 1)

// Maps of grays (used in e.g. bg color assignment or carousel gradients)
$grays-map: (
	"gray01": $gray01,
	"gray02": $gray02,
	"gray03": $gray03,
	"gray04": $gray04,
	"gray05": $gray05,
	"gray06": $gray06,
	"gray07": $gray07,
	"gray08": $gray08,
	"gray09": $gray09,
	"gray10": $gray10,
	"status-critical": $status-critical,
	"status-attention": $status-attention,
	"status-ok": $status-ok
);

// Transparent color
$transparent: transparent;

// OBSOLETE OLD COLORS - to be removed when all components will be updated
// Primary color
$plum: $red;
$purple: $red;
$green: $red;
// OLD COLORS END - to be removed when all components will be updated

/* FILTERS
================
*/
// For recoloring of SVG images (https://codepen.io/sosuke/pen/Pjoqqp)
$filter-blue-dark: brightness(0) saturate(100%) invert(10%) sepia(18%) saturate(1750%) hue-rotate(160deg) brightness(97%) contrast(102%);
$filter-blue-deep: brightness(0) saturate(100%) invert(16%) sepia(17%) saturate(2000%) hue-rotate(160deg) brightness(93%) contrast(92%);
$filter-blue-deeper: brightness(0) saturate(100%) invert(10%) sepia(76%) saturate(686%) hue-rotate(163deg) brightness(92%) contrast(94%);
$filter-blue-mid-mid: brightness(0) saturate(100%) invert(32%) sepia(20%) saturate(1060%) hue-rotate(159deg) brightness(90%) contrast(89%);
$filter-blue-bright: brightness(0) saturate(100%) invert(33%) sepia(42%) saturate(7499%) hue-rotate(203deg) brightness(99%) contrast(101%);
$filter-blue-pale: brightness(0) saturate(100%) invert(85%) sepia(5%) saturate(1716%) hue-rotate(188deg) brightness(104%) contrast(104%);
$filter-blue-faint: brightness(0) saturate(100%) invert(99%) sepia(27%) saturate(1467%) hue-rotate(177deg) brightness(97%) contrast(111%);
$filter-green-bright: brightness(0) saturate(100%) invert(75%) sepia(65%) saturate(508%) hue-rotate(34deg) brightness(101%) contrast(88%);
$filter-green-faint: brightness(0) saturate(100%) invert(92%) sepia(19%) saturate(225%) hue-rotate(44deg) brightness(104%) contrast(97%);
$filter-orange-bright: brightness(0) saturate(100%) invert(51%) sepia(74%) saturate(2201%) hue-rotate(358deg) brightness(99%) contrast(110%);
$filter-orange-light: brightness(0) saturate(100%) invert(58%) sepia(11%) saturate(6281%) hue-rotate(3deg) brightness(110%) contrast(104%);
$filter-orange-pale: brightness(0) saturate(100%) invert(87%) sepia(19%) saturate(414%) hue-rotate(337deg) brightness(101%) contrast(94%);
$filter-orange-faint: brightness(0) saturate(100%) invert(96%) sepia(41%) saturate(554%) hue-rotate(297deg) brightness(102%) contrast(106%);
$filter-orange-off-white: brightness(0) saturate(100%) invert(93%) sepia(63%) saturate(179%) hue-rotate(294deg) brightness(103%) contrast(101%);

$filter-red: brightness(0) saturate(100%) invert(24%) sepia(76%) saturate(2639%) hue-rotate(356deg) brightness(91%) contrast(94%);
$filter-red-inverse: brightness(0) saturate(100%) invert(46%) sepia(66%) saturate(3753%) hue-rotate(341deg) brightness(106%) contrast(101%);
$filter-yellow: brightness(0) saturate(100%) invert(67%) sepia(50%) saturate(812%) hue-rotate(2deg) brightness(104%) contrast(104%);
$filter-yellow-inverse: brightness(0) saturate(100%) invert(72%) sepia(84%) saturate(573%) hue-rotate(359deg) brightness(104%) contrast(104%);

// Primary colors
$filter-black: brightness(0) saturate(100%);
$filter-white: brightness(0) saturate(100%) invert(100%) hue-rotate(54deg)
brightness(105%) contrast(101%);

// 9 shades of grey
$filter-gray10: brightness(0) saturate(100%) invert(91%) sepia(1%)
saturate(154%) hue-rotate(169deg) brightness(108%) contrast(98%);
$filter-gray09: brightness(0) saturate(100%) invert(93%) sepia(18%)
saturate(48%) hue-rotate(206deg) brightness(100%) contrast(98%);
$filter-gray08: brightness(0) saturate(100%) invert(95%) sepia(3%)
saturate(632%) hue-rotate(204deg) brightness(99%) contrast(92%);
$filter-gray07: brightness(0) saturate(100%) invert(89%) sepia(14%)
saturate(150%) hue-rotate(209deg) brightness(101%) contrast(87%);
$filter-gray06: brightness(0) saturate(100%) invert(83%) sepia(9%)
saturate(228%) hue-rotate(161deg) brightness(92%) contrast(89%);
$filter-gray05: brightness(0) saturate(100%) invert(73%) sepia(11%) saturate(342%) hue-rotate(158deg) brightness(87%) contrast(87%);
$filter-gray04: brightness(0) saturate(100%) invert(55%) sepia(9%)
saturate(1021%) hue-rotate(208deg) brightness(103%) contrast(84%);
$filter-gray03: brightness(0) saturate(100%) invert(48%) sepia(11%)
saturate(578%) hue-rotate(157deg) brightness(94%) contrast(90%);
$filter-gray02: brightness(0) saturate(100%) invert(32%) sepia(20%)
saturate(388%) hue-rotate(159deg) brightness(90%) contrast(84%);

/* BODY
================
*/

// Settings for the `<body>` element.
$body-color: $blue-dark;
$body-color-light: $white;
$body-bg: $white;
$body-bg-inverse: $blue-dark;

// Text muted
$text-muted: $gray03;
$text-muted-inverse: $gray06;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-shadows: true;
$enable-validation-icons: false;
// $enable-gradients:          true;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray02;
$yiq-text-light: $white;

/* GRID
================
*/
// Grid breakpoint values:
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-custom-lg1: 1280px;
$breakpoint-custom-lg2: 1366px;
$breakpoint-xl: 1600px;
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
xs: 0,
sm: $breakpoint-sm,
md: $breakpoint-md,
lg: $breakpoint-lg,
xl: $breakpoint-xl,
);

// Grid container values:
$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-custom-lg1: 1200px;
$container-custom-lg2: 1286px;
$container-xl: 1360px;
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
sm: $container-sm,
md: $container-md,
lg: $container-lg,
xl: $container-xl,
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: $base-unit * 2;

/*
TYPOGRAPHY
=================
*/

// Font Family (used mainly in _reboot.scss)
// $font-family-base: MierB03, Helvetica, Arial, sans-serif;

/* Body Text
=================
	#xl = extra large
	#lg = large
	#md = medium
	#sm = small
*/
//Desktop
$body-xl-font-size: $base-unit * 1.375; //22px
$body-lg-font-size: $base-unit * 1.125; //18px
$body-md-font-size: $base-unit; //16px
$body-sm-font-size: $base-unit * .875; //14px
$body-xs-font-size: $base-unit * .75; //12px

$body-xl-line-height: $base-unit * 2.5; //40px
$body-lg-line-height: $base-unit * 2; //32px
$body-md-line-height: $base-unit * 1.5; //24px
$body-sm-line-height: $base-unit * 1.5; //24px

//Mobile
$body-xl-font-size-m: $base-unit * 1.125; //18px
$body-lg-font-size-m: $base-unit * 1; //16px
$body-md-font-size-m: $base-unit * .875; //14px
$body-sm-font-size-m: $base-unit * .75; //12px

$body-xl-line-height-m: $base-unit * 1.5; //24px
$body-lg-line-height-m: $base-unit * 1.313; //21px
$body-md-line-height-m: $base-unit * 1.125; //18px
$body-sm-line-height-m: $base-unit * 1.125; //18px

/* Links Text
=================
	#xl = extra large
	#lg = large
	#md = medium
	#sm = small
*/
/*
$link-xl-font-size:         $body-xl-font-size;
$link-lg-font-size:         $body-lg-font-size;
$link-md-font-size:         $body-md-font-size;
$link-sm-font-size:         $body-sm-font-size;
$link-xl-line-height:       $body-xl-line-height;
$link-lg-line-height:       $body-lg-line-height;
$link-md-line-height:       $body-md-line-height;
$link-sm-line-height:       $body-sm-line-height; */

// Links
$link-primary-color-dark: $blue-dark;
$link-primary-color-light: $white; //for inverse styles
$link-secondary-color: $blue-dark;

$link-color: $link-primary-color-dark;
$link-hover-color: $link-primary-color-dark;
$link-hover-decoration: none;

/* Paragraphs
=================
*/
$paragraph-margin-bottom: $base-unit * 1.5;

/* Headings
=================
*/
//Desktop sizes
$h1-font-size: $base-unit * 3.5; //56px
$h2-font-size: $base-unit * 2.875; //46px;
$h3-font-size: $base-unit * 2; //32px;
$h4-font-size: $base-unit * 1.625; //26px
$h5-font-size: $base-unit * 1.5; //24px
$h6-font-size: $base-unit * 1.25; //20px;
$h7-font-size: $base-unit * 1.125; //18px;

$h1-line-height: $base-unit * 4.5; //72px;
$h2-line-height: $base-unit * 4; //64px;
$h3-line-height: $base-unit * 3; //48px;
$h4-line-height: $base-unit * 2.5; //40px;
$h5-line-height: $base-unit * 2.5; //40px;
$h6-line-height: $base-unit * 2; //32px;
$h7-line-height: $base-unit * 1.5; //24px;

//Mobile sizes
$h1-font-size-m: $base-unit * 2.625; //42px
$h2-font-size-m: $base-unit * 2.125; //34px;
$h3-font-size-m: $base-unit * 1.5; //24px;
$h4-font-size-m: $base-unit * 1.25; //20px
$h5-font-size-m: $base-unit * 1.125; //18px
$h6-font-size-m: $base-unit; //16px;
$h7-font-size-m: $base-unit * .875; //14px;

$h1-line-height-m: $base-unit * 3.375; //54px;
$h2-line-height-m: $base-unit * 3.125; //50px;
$h3-line-height-m: $base-unit * 2.25; //36px;
$h4-line-height-m: $base-unit * 1.875; //30px;
$h5-line-height-m: $base-unit * 1.688; //27px;
$h6-line-height-m: $base-unit * 1.5; //24px;
$h7-line-height-m: $base-unit * 1.313; //21px;

$h1-font-weight: 800;
$h2-font-weight: 800;
$h3-font-weight: 800;
$h4-font-weight: 800;
$h5-font-weight: 800;
$h6-font-weight: 800;
$h7-font-weight: 800;
$headings-margin-bottom: $base-unit * 1.5;
$headings-margin-bottom-small: $base-unit;

/* Border
=================================
bootstrap border color overwrite
*/
$border-color: $gray08;

/* ELEVATION STYLES
=================================
*/

// Transparent border to maintain same behaviour for elements without border
$elevation-border-transparent: 1px solid transparent;

// Shadows
$elevation-shadow-sizes: (
"l": 0 40px 60px -20px,
"m": 0 20px 40px -20px,
"s": 0 10px 20px -10px,
);

$elevation-shadow-alphas: (
"l1": .15,
"l2": .3,
"m1": .15,
"m2": .4,
"s1": .1,
"s2": .3,
);
$elevation-box-shadow-color: $blue-dark;

$elevation-shadow-l-alpha1-inverse: .32;
$elevation-shadow-l-alpha2-inverse: .8;
$elevation-box-shadow-color-inverse: $black;

// Borders
$elevation-border: 1px solid $gray08;
$elevation-border-inverse: 1px solid $blue-mid;

/* BUTTONS
=================
	on mobiles button will shrink one size down
	#lg = large
	# = medium
	#sm = small
*/

// Button hights
$btn-height-lg: 72px;
$btn-height: 56px;
$btn-height-sm: 40px;
$btn-height-xs: 36px;

// Button themes
$btn-theme-primary: "blue-bright";
$btn-theme-secondary: "blue-bright-secondary";
$btn-theme-monochrome: "monochrome-secondary";

$btn-disabled-opacity: .32;
$btn-themes: (
"blue-bright": (
	"font-color": $white,
	"font-color-disabled-alt": $white,
	"font-color-disabled-alt-inverse": #56656f,
	"background-color": $blue-bright,
	"background-color-hover": $blue-bright-hover,
	"background-color-focus": $blue-bright-focus,
	"background-color-disabled": $blue-bright,
	"background-color-disabled-alt": #add1fc,
	"background-color-disabled-alt-inverse": #05386c,
	"filter-color": $filter-white,
	"filter-color-disabled-alt": $filter-white,
	"filter-color-disabled-alt-inverse": brightness(0) saturate(100%)
	invert(37%) sepia(20%) saturate(343%) hue-rotate(161deg)
	brightness(98%) contrast(89%),
	"shadow-normal1": 0 8px 32px -8px rgba($blue-bright, .4),
	"shadow-normal2": 0 16px 32px -16px rgba($blue-dark, .16),
	"shadow-hover1": 0 8px 32px -8px rgba($blue-bright, .72),
	"shadow-hover2": 0 16px 32px -16px rgba($blue-dark, .24),
	"shadow-focus1": 0 8px 32px -8px rgba($blue-bright, .72),
	"shadow-focus2": 0 16px 32px -16px rgba($blue-dark, .24),
	"circle-shadow-normal1": 0 8px 16px -6px rgba($blue-bright, .4),
	"circle-shadow-normal2": 0 8px 16px -6px rgba($blue-dark, .16),
	"circle-shadow-hover1": 0 8px 16px -6px rgba($blue-bright, .4),
	"circle-shadow-hover2": 0 12px 16px -4px rgba($blue-dark, .16),
	"circle-shadow-focus1": 0 8px 16px -6px rgba($blue-bright, .4),
	"circle-shadow-focus2": 0 12px 16px -4px rgba($blue-dark, .16),
),
"blue-bright-secondary": (
	"font-color": $blue-bright,
	"font-color-hover": $white,
	"background-color": $white,
	"background-color-hover": $blue-bright-hover,
	"background-color-focus": $blue-bright-focus,
	"background-color-disabled": $white,
	"border-color": $blue-bright,
	"border-color-hover": $blue-bright-hover,
	"border-color-focus": $blue-bright-focus,
	"border-color-disabled": $blue-bright,
	"border-color-disabled-alt": rgba($blue-bright, $btn-disabled-opacity),
	"filter-color": $filter-blue-bright,
	"filter-color-hover": $filter-white,
	"shadow-normal1": none,
	"shadow-normal2": none,
	"shadow-hover1": 0 8px 32px -16px rgba($blue-bright, .72),
	"shadow-hover2": 0 24px 32px -16px rgba($blue-dark, .24),
	"shadow-focus1": 0 8px 32px -8px rgba($blue-bright, .72),
	"shadow-focus2": 0 16px 32px -16px rgba($blue-dark, .24),
	"circle-shadow-normal1": 0 8px 16px -6px rgba($blue-bright, .4),
	"circle-shadow-normal2": 0 8px 16px -6px rgba($blue-dark, .16),
	"circle-shadow-hover1": 0 8px 16px -6px rgba($blue-bright, .4),
	"circle-shadow-hover2": 0 12px 16px -4px rgba($blue-dark, .16),
	"circle-shadow-focus1": 0 8px 16px -6px rgba($blue-bright, .4),
	"circle-shadow-focus2": 0 12px 16px -4px rgba($blue-dark, .16),
),
"green-bright": (
	"font-color": $blue-dark,
	"font-color-disabled-alt": #b0b7bb,
	"font-color-disabled-alt-inverse": $blue-dark,
	"background-color": $green-bright,
	"background-color-hover": $green-bright-hover,
	"background-color-focus": $green-bright-focus,
	"background-color-disabled": $green-bright,
	"background-color-disabled-alt": #dff6bf,
	"background-color-disabled-alt-inverse": #365d2f,
	"filter-color": $filter-blue-dark,
	"filter-color-disabled-alt": brightness(0) saturate(100%) invert(82%)
	sepia(11%) saturate(144%) hue-rotate(158deg) brightness(89%)
	contrast(87%),
	"filter-color-disabled-alt-inverse": $filter-blue-dark,
	"shadow-normal1": 0 8px 32px -8px rgba($green-bright, .4),
	"shadow-normal2": 0 16px 32px -16px rgba($blue-dark, .16),
	"shadow-hover1": 0 8px 32px -8px rgba($green-bright, .72),
	"shadow-hover2": 0 16px 32px -16px rgba($blue-dark, .24),
	"shadow-focus1": 0 8px 32px -8px rgba($green-bright, .72),
	"shadow-focus2": 0 16px 32px -16px rgba($blue-dark, .24),
	"circle-shadow-normal1": 0 8px 16px -6px rgba($green-bright, .4),
	"circle-shadow-normal2": 0 8px 16px -6px rgba($blue-dark, .16),
	"circle-shadow-hover1": 0 8px 16px -6px rgba($green-bright, .4),
	"circle-shadow-hover2": 0 12px 16px -4px rgba($blue-dark, .16),
	"circle-shadow-focus1": 0 8px 16px -6px rgba($green-bright, .4),
	"circle-shadow-focus2": 0 12px 16px -4px rgba($blue-dark, .16),
),
"green-bright-secondary": (
	"font-color": $blue-dark,
	"font-color-hover": $blue-dark,
	"background-color": $white,
	"background-color-hover": $green-bright-hover,
	"background-color-focus": $green-bright-focus,
	"background-color-disabled": $white,
	"border-color": $green-bright,
	"border-color-hover": $green-bright-hover,
	"border-color-focus": $green-bright-focus,
	"border-color-disabled": $green-bright,
	"border-color-disabled-alt": rgba($green-bright, $btn-disabled-opacity),
	"filter-color": $filter-blue-dark,
	"filter-color-hover": $filter-blue-dark,
	"shadow-normal1": none,
	"shadow-normal2": none,
	"shadow-hover1": 0 8px 32px -16px rgba($green-bright, .72),
	"shadow-hover2": 0 24px 32px -16px rgba($blue-dark, .24),
	"shadow-focus1": 0 8px 32px -8px rgba($green-bright, .72),
	"shadow-focus2": 0 16px 32px -16px rgba($blue-dark, .24),
	"circle-shadow-normal1": 0 8px 16px -6px rgba($green-bright, .4),
	"circle-shadow-normal2": 0 8px 16px -6px rgba($blue-dark, .16),
	"circle-shadow-hover1": 0 8px 16px -6px rgba($green-bright, .4),
	"circle-shadow-hover2": 0 12px 16px -4px rgba($blue-dark, .16),
	"circle-shadow-focus1": 0 8px 16px -6px rgba($green-bright, .4),
	"circle-shadow-focus2": 0 12px 16px -4px rgba($blue-dark, .16),
),
"monochrome-secondary": (
	"font-color": $blue-dark,
	"font-color-hover": $white,
	"background-color": $white,
	"background-color-hover": $blue-dark,
	"background-color-focus": $black,
	"background-color-disabled": $white,
	"border-color": $blue-dark,
	"border-color-hover": $blue-dark,
	"border-color-focus": $black,
	"border-color-disabled": $blue-dark,
	"border-color-disabled-alt": rgba($blue-dark, $btn-disabled-opacity),
	"filter-color": $filter-blue-dark,
	"filter-color-hover": $filter-white,
	"shadow-normal1": none,
	"shadow-normal2": none,
	"shadow-hover1": 0 8px 32px -16px rgba($blue-dark, .72),
	"shadow-hover2": 0 24px 32px -16px rgba($blue-dark, .24),
	"shadow-focus1": 0 8px 32px -8px rgba($blue-dark, .72),
	"shadow-focus2": 0 16px 32px -16px rgba($blue-dark, .24),
	"circle-shadow-normal1": 0 8px 16px -6px rgba($blue-dark, .24),
	"circle-shadow-normal2": 0 8px 16px -6px rgba($blue-dark, .08),
	"circle-shadow-hover1": 0 8px 16px -8px rgba($blue-dark, .72),
	"circle-shadow-hover2": 0 12px 16px -8px rgba($blue-dark, .24),
	"circle-shadow-focus1": 0 8px 32px -8px rgba($blue-dark, .72),
	"circle-shadow-focus2": 0 16px 32px -16px rgba($blue-dark, .24),
),
);

// button font, size and shape
$btn-padding-y-lg: $base-unit * 1.25; //20px;
$btn-padding-y: $base-unit; //16px;
$btn-padding-y-sm: $base-unit * .75; //12px;
$btn-padding-y-xs: $base-unit * .5; //8px;

$btn-padding-x-lg: 40px;
$btn-padding-x: 32px;
$btn-padding-x-sm: 24px;
$btn-padding-x-xs: 20px;

$btn-font-size-lg: $body-lg-font-size; //18
$btn-font-size: $body-sm-font-size; //14
$btn-font-size-sm: $body-xs-font-size; //12

// Button span line height is different from design so two line text hasn't spaces between the lines too big
$btn-span-line-height-lg: $base-unit * 1.25; //20
$btn-span-line-height: $base-unit; //16
$btn-span-line-height-sm: 14px;
$btn-span-line-height-xs: 12px;

$btn-line-height-lg: $btn-span-line-height-lg; //$body-lg-line-height; //32
$btn-line-height: $btn-span-line-height; //$body-sm-line-height; //24
$btn-line-height-sm: $btn-span-line-height-sm; //$base-unit; //16

$btn-letter-spacing-lg: 1px;
$btn-letter-spacing: 1px;
$btn-letter-spacing-sm: 1px;
$btn-letter-spacing-xs: .7px;

$btn-border-radius-lg: 36px;
$btn-border-radius: 28px;
$btn-border-radius-sm: 20px;
$btn-border-radius-xs: 20px;

$btn-border-width: 2px;
$btn-border-width-sm: 1px;
$btn-circle-border-width: 1px;

// button icon
$btn-icon-height-lg: 32px;
$btn-icon-height: 24px;
$btn-icon-height-sm: 16px;
$btn-icon-height-xs: 16px;
$btn-icon-middle-height: 24px;

$btn-icon-padding-text-lg: 24px;
$btn-icon-padding-text: 16px;
$btn-icon-padding-text-sm: 8px;
$btn-icon-padding-text-xs: 8px;



/* Message bar
===================
*/
$cmp-message-bar__height--mobile: $base-unit * 5;


/* HEADER
===================
*/
$cmp-header__height--mobile: $base-unit * 4;		// 64px
$cmp-header__height--desktop: $base-unit * 6.25;	// 100px
$cmp-header__border-color: $gray08;
$header-box-shadow-color: rgba(45, 54, 76, .12);
$box-shadow:  0 .5rem 1rem rgba($black, .15);

$cmp-header-mobile-navigation__max-height: calc(100vh - ( #{$cmp-message-bar__height--mobile} + #{$cmp-header__height--mobile} ));


/* REGION SELECTOR
===================
*/

$region-selector-flags: url("https://static3.avast.com/web/i/v2/components/icons/flags/flag-language-selector-v3.svg");

/* CARDS
================
*/

$card-border-width: 0;
$card-border-radius: 0;
$card-cap-bg: transparent;
$card-bg: transparent;
$card-color: inherit;

$card-bg-hover: $blue-faint;

$card-badge-bg-hover: $orange-pale;
$card-badge-shadow-hover: none;

/* ACCORDION
================
*/
$accordion-border: solid 1px $gray08;

// Headers
$accordion-header-color-hover: $blue-bright;
$accordion-header-color-inverse-hover: $white;
$accordion-icon-color: $filter-gray03;
$accordion-icon-color-inverse: $filter-gray06;
$accordion-icon-color-hover: $filter-blue-bright;
$accordion-icon-color-inverse-hover: $filter-white;

/* MEDIA object
===============
*/
//bg position
$bg-offset-lg: calc(
((100% - #{$container-lg}) / 2) + ((#{$container-lg} / 4) + #{$base-unit})
);
$bg-offset-lg1: calc(
((100% - #{$container-custom-lg1}) / 2) +
((#{$container-custom-lg1} / 4) + #{$base-unit})
);
$bg-offset-lg2: calc(
((100% - #{$container-custom-lg2}) / 2) +
((#{$container-custom-lg2} / 4) + #{$base-unit})
);
$bg-offset-xl: calc(
((100% - #{$container-xl}) / 2) + ((#{$container-xl} / 4) + #{$base-unit})
);

/* NAVS
=================
*/
/* $nav-link-font-size-horizontal:       $body-md-font-size;
$nav-link-font-size-vertical:         $body-sm-font-size;
$nav-link-line-height-horizontal:     $body-md-line-height;
$nav-link-line-height-vertical:       $base-unit*1.375; */

$nav-link-padding-y: $base-unit;
$nav-link-padding-y-sm: $base-unit * .5625;
$nav-link-padding-x-xl: $base-unit * 3;
$nav-link-padding-x-lg: $base-unit * 2.5;
$nav-link-padding-x-md: $base-unit * 2;
$nav-link-padding-x-sm: $base-unit * .5;
$nav-link-padding-x-vertical: $base-unit * 3;

$nav-link-max-width-xl: $base-unit * 20;
$nav-link-max-width-lg: $base-unit * 15;
$nav-link-max-width-md: $base-unit * 12;

$nav-tabs-border-color: $gray08;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-color: $blue-bright;
$nav-tabs-link-active-box-shadow: inset 0 -3px 0 0 $blue-bright;
$nav-tabs-link-active-box-shadow-inv: inset 0 -3px 0 0 $orange-bright;

/* TAGLINES
=================
	#lg = large
	#md = medium
	#sm = small
*/
//Desktop
$tagline-lg-font-size: $base-unit * .9375; //15px
$tagline-md-font-size: $base-unit * .8125; //13px
$tagline-sm-font-size: $base-unit * .6875; //11px
$tagline-lg-line-height: $base-unit * 1.5;
$tagline-md-line-height: $base-unit;
$tagline-sm-line-height: $base-unit;
//Mobile
$tagline-lg-font-size-m: $base-unit * .813; //13px
$tagline-md-font-size-m: $base-unit * .688; //11px
$tagline-sm-font-size-m: $base-unit * .563; //9px
$tagline-lg-line-height-m: $base-unit * 1.125;
$tagline-md-line-height-m: $base-unit;
$tagline-sm-line-height-m: $base-unit * .75;

$tagline-font-weight: 800;
$tagline-lg-letter-spacing: 2.5px;
$tagline-md-letter-spacing: 2px;
$tagline-sm-letter-spacing: 1.5px;

/* Caption
=================
*/
//Desktop
$caption-font-size: $base-unit * .8125;
$caption-line-height: $base-unit;

//Mobile
$caption-font-size-m: $base-unit * .688; //11px

/* Breadcrumb
=================
*/

$breadcrumb-font-size: 12px;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $blue-dark;
$breadcrumb-active-color: $blue-dark;
$breadcrumb-inactive-color: $gray03;
$breadcrumb-active-color-inverse: $white;
$breadcrumb-inactive-color-inverse: $gray06;
$breadcrumb-divider: quote("❯");

/* Tables
=================
*/

$table-bg: $white;
$table-color: $blue-dark;
$table-width-sm: 780px;
$table-width-md: 1011px;
$table-width-lg: 1128px;
$table-width-xl: 1128px;
$table-cell-height-sm: 56px;
$table-cell-height-md: 64px;
$table-cell-height-lg: 88px;
$table-cell-height-xl: 88px;
$table-cell-highlight: $blue-faint;
$table-border-color: $gray08;
$table-border-width: 1px;
$table-icon-filter-true: $filter-blue-bright;
$table-icon-filter-false: $filter-gray07;

/* Hero
==================
*/

$hero-headline-font-weight: 800;
$hero-supheadline-font-size: $body-xl-font-size;
$hero-subheadline-font-size: $body-xl-font-size;
$hero-min-height: 500px;
$hero-image-height: 850px;

/* Tooltip
=================
*/

$tooltip-font-size: 10px;
$tooltip-lg-font-size: $base-unit * .8125; //13px
$tooltip-max-width: $base-unit * 17.5;
$tooltip-bg: $blue-dark;
$tooltip-padding-y: $base-unit * .75;
$tooltip-padding-x: $base-unit * .75;
$tooltip-opacity: 1;
$tooltip-border-radius: 5px;
$tooltip-arrow-width: 10px;
$tooltip-arrow-height: 5px;

/* Popover
=================
*/

$popover-font-size: $body-md-font-size;
$popover-max-width: $base-unit * 17.5;
$popover-header-bg: transparent;
$popover-box-shadow: 0 10px 20px -10px rgba($blue-dark, .1);
$popover-border-radius: 5px;
$popover-arrow-width: 13px;
$popover-arrow-height: 8px;
$popover-border-color: $gray08;
$popover-arrow-outer-color: $gray08;

/* Forms input
=============
*/
$input-border-radius-sm: $base-unit;
$input-border-radius: 20px;
$input-border-radius-lg: $base-unit * 1.5;

$input-padding-y-sm: $base-unit * .25;
$input-padding-x-sm: $base-unit;
$input-font-size-sm: $body-sm-font-size;
$input-line-height-sm: $body-sm-line-height;

$input-padding-y: $base-unit * .5;
$input-padding-x: $base-unit;
$input-font-size: $body-sm-font-size;
$input-line-height: $body-sm-line-height;

$input-padding-y-lg: $base-unit * .75;
$input-padding-x-lg: $base-unit;
$input-font-size-lg: $body-md-font-size;
$input-line-height-lg: $body-md-line-height;

$input-disabled-bg: $white;

$input-color: $blue-dark;
$input-border-color: $gray08;
$input-border-hover-color: $gray05;
$input-border-width: 1px;
$input-box-shadow: inset 0 2px 4px rgba($blue-dark, .12);

$input-height-border: 0; //$input-border-width * 2;

$input-height-inner: add($input-line-height, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * .5, $input-padding-y);
$input-height-inner-quarter: add(
$input-line-height * .25, $input-padding-y / 2
);

$input-height: add(
$input-line-height, add($input-padding-y * 2, $input-height-border, false)
);
$input-height-sm: add(
$input-line-height-sm, add($input-padding-y-sm * 2, $input-height-border, false)
);
$input-height-lg: add(
$input-line-height-lg, add($input-padding-y-lg * 2, $input-height-border, false)
);
$input-height-border: $input-border-width * 2;

$input-focus-bg: $white;
//$input-focus-border-color: $blue-bright;
$input-focus-color: $blue-dark;
$input-focus-width: 4px;
$input-focus-box-shadow: 0 0 0 $input-focus-width #0070f63d;
$input-focus-box-shadow-inverted: 0 0 0 $input-focus-width #0070f63d;

$input-placeholder-color: $gray03;

$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

$form-text-margin-top: -$base-unit * .5;
$form-text-font-size: $base-unit * .75;
$form-text-line-height: $base-unit;

$form-feedback-font-size: $form-text-font-size;

/* Custom Forms
===============
*/
$custom-control-indicator-checked-bg: $transparent;
$custom-control-indicator-size: $base-unit * 1.5;
$custom-control-indicator-active-bg: $gray09;
$custom-control-indicator-box-shadow: none;
$custom-control-indicator-border-color: $input-border-color;
$custom-control-indicator-bg-size: $base-unit $base-unit;
//$custom-control-label-disabled-color: $gray08;
$custom-control-opacity-disabled: .5;
$custom-control-indicator-checked-border-color: $input-border-color;

// Characters which are escaped by the escape-svg function
$escaped-characters: (("<", "%3c"), (">", "%3e"), ("#", "%23"));

//Custom select
$custom-select-padding-x: $input-padding-x;
$custom-select-border-radius: $input-border-radius;
$custom-select-bg-size: 16px 16px; // In pixels because image dimensions
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='none' fill-rule='evenodd' stroke='#{$blue-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 4L10 8.103 6 12' transform='rotate(90 8 8)'/></svg>");
$custom-select-indicator-inverse: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='none' fill-rule='evenodd' stroke='#{$white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.2' d='M6 4L10 8.103 6 12' transform='rotate(90 8 8)'/></svg>");
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right
$custom-select-padding-x center / $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-box-shadow: 0 10px 20px -10px rgba($blue-dark, .16);

$custom-select-focus-border-color: $blue-bright;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width
rgba($gray05, .1);

$custom-select-border-radius-sm: $input-border-radius-sm;
$custom-select-border-radius-lg: $input-border-radius-lg;

//Custom checkbox
$custom-checkbox-indicator-border-radius: 6px;
$custom-checkbox-indicator-checked-color: $blue-dark;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' fill-rule='evenodd' stroke='#{$blue-dark}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 8L6.125 12 14 4'/></svg>");
$custom-checkbox-indicator-icon-checked-white: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' fill-rule='evenodd' stroke='#{$white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 8L6.125 12 14 4'/></svg>");
$custom-checkbox-indicator-indeterminate-bg: $white;

//Custom radio
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><circle cx='8' cy='8' r='3' fill='#{$blue-dark}' fill-rule='evenodd'/></svg>");
$custom-radio-indicator-icon-checked-white: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><circle cx='8' cy='8' r='3' fill='#{$white}' fill-rule='evenodd'/></svg>");

/* Dropdowns
=================
*/
$enable-caret: false;
$dropdown-min-width: 100%;

$dropdown-font-size: $body-sm-font-size;

$dropdown-border-color: $gray08;
$dropdown-border-radius: $base-unit * 1.25;
$dropdown-box-shadow: 0 10px 20px -10px rgba($gray08, .2);

$dropdown-link-color: $body-color;
$dropdown-link-hover-color: $body-color;
$dropdown-link-hover-bg: $orange-faint;
$dropdown-link-active-color: $body-color;
$dropdown-link-active-bg: transparent;
//$dropdown-link-disabled-color: rgba($blue-dark, .32);

$dropdown-item-padding-y: $base-unit/2;

// sprite images
$sprite-images: (
	"icons": (
		sizes: (16, 24, 32, 40),
		names: (
			"arrow-right": (
				row: 1,
				position: 1,
			),
			"arrow-left": (
				row: 1,
				position: 2,
			),
			"arrow-up": (
				row: 1,
				position: 3,
			),
			"arrow-down": (
				row: 1,
				position: 4,
			),
			"download": (
				row: 1,
				position: 5,
			),
			"next": (
				row: 1,
				position: 6,
			),
			"back": (
				row: 1,
				position: 7,
			),
			"up": (
				row: 1,
				position: 8,
			),
			"down": (
				row: 1,
				position: 9,
			),
			"plus": (
				row: 1,
				position: 10,
			),
			"minus": (
				row: 1,
				position: 11,
			),
			"cross": (
				row: 1,
				position: 12,
			),
			"send": (
				row: 1,
				position: 13,
			),
			"refresh": (
				row: 2,
				position: 1,
			),
			"external": (
				row: 2,
				position: 2,
			),
			"play-oval": (
				row: 2,
				position: 3,
			),
			"play": (
				row: 2,
				position: 4,
			),
			"play-l": (
				row: 2,
				position: 5,
			),
			"check-oval": (
				row: 2,
				position: 6,
			),
			"refresh-2": (
				row: 2,
				position: 7,
			),
			"next-s": (
				row: 2,
				position: 8,
			),
			"back-s": (
				row: 2,
				position: 9,
			),
			"up-s": (
				row: 2,
				position: 10,
			),
			"down-s": (
				row: 2,
				position: 11,
			),
			"last": (
				row: 2,
				position: 12,
			),
			"first": (
				row: 2,
				position: 13,
			),
			"bracets": (
				row: 2,
				position: 14,
			),
			"sound-off": (
				row: 2,
				position: 15,
			),
			"sound-on": (
				row: 2,
				position: 16,
			),
			"home": (
				row: 3,
				position: 1,
			),
			"account": (
				row: 3,
				position: 2,
			),
			"store": (
				row: 3,
				position: 3,
			),
			"support": (
				row: 3,
				position: 4,
			),
			"all-products": (
				row: 3,
				position: 5,
			),
			"search": (
				row: 3,
				position: 6,
			),
			"hardware": (
				row: 3,
				position: 7,
			),
			"headset": (
				row: 3,
				position: 8,
			),
			"info": (
				row: 3,
				position: 9,
			),
			"hover-info": (
				row: 3,
				position: 10,
			),
			"thumbs-down": (
				row: 3,
				position: 11,
			),
			"thumbs-up": (
				row: 3,
				position: 12,
			),
			"clipboard": (
				row: 3,
				position: 13,
			),
			"article": (
				row: 3,
				position: 14,
			),
			"calculator": (
				row: 4,
				position: 1,
			),
			"chat": (
				row: 4,
				position: 2,
			),
			"filter": (
				row: 4,
				position: 3,
			),
			"phone": (
				row: 4,
				position: 4,
			),
			"email": (
				row: 4,
				position: 5,
			),
			"warning": (
				row: 4,
				position: 6,
			),
			"alarm-clock": (
				row: 4,
				position: 7,
			),
			"dollar-sign": (
				row: 4,
				position: 8,
			),
			"education": (
				row: 4,
				position: 9,
			),
			"question": (
				row: 4,
				position: 10,
			),
			"hand-right": (
				row: 4,
				position: 11,
			),
			"hand-left": (
				row: 4,
				position: 12,
			),
			"bullet-arrow-2": (
				row: 4,
				position: 13,
			),
			"cross-oval": (
				row: 4,
				position: 14,
			),
			"placeholder": (
				row: 5,
				position: 1,
			),
			"burger": (
				row: 5,
				position: 2,
			),
			"quote": (
				row: 5,
				position: 3,
			),
			"money-back": (
				row: 5,
				position: 4,
			),
			"pc": (
				row: 5,
				position: 5,
			),
			"pin": (
				row: 5,
				position: 6,
			),
			"tick": (
				row: 5,
				position: 7,
			),
			"star": (
				row: 5,
				position: 8,
			),
			"star-half": (
				row: 5,
				position: 9,
			),
			"bullet-arrow": (
				row: 5,
				position: 10,
			),
			"check-table": (
				row: 5,
				position: 11,
			),
			"cross-table": (
				row: 5,
				position: 12,
			),
			"time-to-read": (
				row: 5,
				position: 13,
			),
			"radio-bullet": (
				row: 5,
				position: 14,
			),
			"pause": (
				row: 5,
				position: 15,
			)
		)
	),
	"platforms": (
		sizes: (16, 24, 32),
		names: (
			"win": (
				row: 1,
				position: 1,
			),
			"mac": (
				row: 1,
				position: 2,
			),
			"android": (
				row: 1,
				position: 3,
			),
			"ios": (
				row: 1,
				position: 4,
			),
			"family": (
				row: 1,
				position: 5,
			),
			"server": (
				row: 1,
				position: 6,
			),
			"linux": (
				row: 1,
				position: 7,
			),
			"multi-device": (
				row: 1,
				position: 8,
			),
			"cloud": (
				row: 1,
				position: 9,
			)
		)
	),
	"social": (
		sizes: (16, 24, 32),
		names: (
			"facebook": (
				row: 1,
				position: 1,
			),
			"instagram": (
				row: 1,
				position: 2,
			),
			"linkedin": (
				row: 1,
				position: 3,
			),
			"twitter": (
				row: 1,
				position: 4,
			),
			"youtube": (
				row: 1,
				position: 5,
			),
			"google-developers": (
				row: 1,
				position: 6,
			),
			"blog": (
				row: 1,
				position: 7,
			),
			"share": (
				row: 1,
				position: 8,
			),
			"rss": (
				row: 1,
				position: 9,
			)
		)
	)
);

// supported languages
$supported-languages: 'en','no','sv','fi','da','es','de','fr','it','nl','pt','el','cs','pl','hu','ro','ru','tr','zh','ar','ko','he','th','ca','tl','id','ms','fa','vi','hi','ja','uk';
