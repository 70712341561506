@mixin localesBG($locale, $path) {

	@if $locale == latam {
		.mod-es-ar &,
		.mod-pt-br &,
		.mod-es-cl &,
		.mod-es-co &,
		.mod-es-us &,
		.mod-es-mx &,
		.mod-es-ww & {
			background-image: url(#{$path});
		}
	} @else if $locale == universal {
		.mod-en-za &,
		.mod-en-ae &,
		.mod-he-il &,
		.mod-ru-kz &,
		.mod-ru-ru &,
		.mod-ar-sa &,
		.mod-ar-ww &,
		.mod-en-au &,
		.mod-en-in &,
		.mod-hi-in &,
		.mod-id-id &,
		.mod-ms-my &,
		.mod-en-my &,
		.mod-en-nz &,
		.mod-en-ph &,
		.mod-tl-ph &,
		.mod-en-sg &,
		.mod-vi-vn &,
		.mod-ja-jp &,
		.mod-ko-kr &,
		.mod-zh-cn &,
		.mod-zh-tw &,
		.mod-th-th &,
		.mod-en-ww & {
			background-image: url(#{$path});
		}
	} @else if $locale == europe {
		.mod-fr-ca &,
		.mod-nl-be &,
		.mod-fr-be &,
		.mod-ca-es &,
		.mod-cs-cz &,
		.mod-da-dk &,
		.mod-de-de &,
		.mod-es-es &,
		.mod-fr-fr &,
		.mod-it-it &,
		.mod-hu-hu &,
		.mod-nl-nl &,
		.mod-no-no &,
		.mod-pl-pl &,
		.mod-pt-pt &,
		.mod-de-ch &,
		.mod-cs-sk &,
		.mod-fr-ch &,
		.mod-fi-fi &,
		.mod-sv-se &,
		.mod-tr-tr &,
		.mod-en-gb &,
		.mod-el-gr &,
		.mod-uk-ua &,
		.mod-ru-ua &,
		.mod-en-en & {
			background-image: url(#{$path});
		}
	} @else if $locale == usa {
		.mod-en-ca &,
		.mod-en-us & {
			background-image: url(#{$path});
		}
	} @else {
		@error "Unknown locale #{$locale}.";
	}
}