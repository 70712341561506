/*
	Avast Mixin for image from sprite.

	Author: Ondřej Ochodnický

	Mixin parameters:
	$sprite - name of the sprite
	$name - name of the image
	$size - size of the image
*/

@mixin sprite-image($sprite, $name, $size) {
	$sprite-map: map-get(map-get($sprite-images, $sprite), names);
	$row: map-get(map-get($sprite-map, $name), row) - 1;
	$position: map-get(map-get($sprite-map, $name), position) - 1;
	$gap-position: 0;
	$gap-row: 0;

	/* add row and col gap to all items
	- except first row and each fist item in row */
	@if $row != 0 {
		$gap-row: 16;
	}
	@if $position != 0 {
		$gap-position: 16;
	}

	width: #{$size}px;
	height: #{$size}px;
	background-image: url("https://static3.avast.com/web/i/v2/components/icons/sprites/#{$sprite}-#{$size}.svg?v5");
	background-position: -#{($position * $size) + ($gap-position * $position)}px -#{(
	$row * $size
) + ($gap-row * $row)}px;

	/* mobile size scaling */
	@if $size != 16 {
		$sizeMobile: $size - 8;

		@include media-breakpoint-down(md) {
			width: #{$sizeMobile}px;
			height: #{$sizeMobile}px;
			background-image: url("https://static3.avast.com/web/i/v2/components/icons/sprites/#{$sprite}-#{$sizeMobile}.svg?v5");
			background-position: -#{($position * $sizeMobile) +
			($gap-position * $position)}px -#{($row * $sizeMobile) +
			($gap-row * $row)}px;
		}
	}
}
