/*
 Button color theming
*/
// Primary
@mixin btn-primary ($theme, $btn-themes: $btn-themes) {
	$theme-values: map-get($btn-themes, $theme);
	$shadow-normal: map-get($theme-values, "shadow-normal1"), map-get($theme-values, "shadow-normal2");
	$shadow-focus: map-get($theme-values, "shadow-focus1"), map-get($theme-values, "shadow-focus2");
	$font-color: map-get($theme-values, "font-color");


	color: $font-color;
	background-color: map-get($theme-values, "background-color");
	box-shadow: $shadow-normal;

	.btn-icon { filter: map-get($theme-values, "filter-color"); }
	
	&:before {
		background-color: map-get($theme-values, "background-color");
		border-width: 0;
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
		color: $font-color;
		background-color: map-get($theme-values, "background-color-disabled");
		box-shadow: none;

		// On inverse only alt opacity is different.
		// Must come first so .light class override this.
		.inverse &.alt-opacity {
			color: map-get($theme-values, "font-color-disabled-alt-inverse");
			background-color: map-get($theme-values, "background-color-disabled-alt-inverse");

			.btn-icon { filter: map-get($theme-values, "filter-color-disabled-alt-inverse"); }
		}

		&.alt-opacity,
		.light &.alt-opacity {
			opacity: 1;
			color: map-get($theme-values, "font-color-disabled-alt");
			background-color: map-get($theme-values, "background-color-disabled-alt");

			.btn-icon { filter: map-get($theme-values, "filter-color-disabled-alt"); }
			
		}
	}

	// Mouse Actions
	&:not(:disabled):not(.disabled) {
		//hover state
		&:hover {
			@include btn-hover-primary ($theme, $btn-themes);
		}

		//active and focus state
		&:active,
		&.active,
		&:focus,
		&.focus,
		&:active:focus,
		&:hover:active,
		&:hover:focus {
			color: $font-color;
			box-shadow: $shadow-focus;
			outline: none;

			&:before {
				background-color: map-get($theme-values, "background-color-focus");
			}
		}
	}
}


// Secondary / Outline Buttons
@mixin btn-secondary ($theme, $btn-themes: $btn-themes) {
	$theme-values: map-get($btn-themes, $theme);
	$shadow-normal: map-get($theme-values, "shadow-normal1"), map-get($theme-values, "shadow-normal2");
	$shadow-focus: map-get($theme-values, "shadow-focus1"), map-get($theme-values, "shadow-focus2");
	$font-color: map-get($theme-values, "font-color");

	background-color: transparent;
	border-color: transparent;

	// Inverse outlined color are same for all themes.
	// Must come first so class .light is overriden.
	.inverse & {
		color: $white;

		.btn-icon { filter: $filter-white;}
		
		&:before {
			background-color: transparent;
			border-color: $white;
		}

		&.disabled,
		&:disabled {
			opacity: $btn-disabled-opacity;
			color: $white;
			background-color: transparent;
			border-color: $white;
	
			&.alt-opacity {
				opacity: 1;
				color: #56656F;
				background-color: $blue-dark;
				border-color: #56656F;
	
				.btn-icon {
					filter: $filter-white;
					opacity: $btn-disabled-opacity;
				}
			}
		}

		&:not(:disabled):not(.disabled) {
			box-shadow: none;

			//hover state
			&:hover {
				@include btn-hover-secondary ($theme, true, $btn-themes: $btn-themes);
			}

			//active and focus state
			&:active,
			&.active,
			&:focus,
			&.focus,
			&:active:focus,
			&:hover:active,
			&:hover:focus {
				color: $blue-dark;
				box-shadow: none;

				.btn-icon { filter: $filter-blue-dark;}

				&:before {
					background-color: $white;
					border-color: $white;
				}
			}
		}
	}

	// Basic and .light class styles
	&,
	.light & {
		color: $font-color;
		box-shadow: none;

		.btn-icon { filter: map-get($theme-values, "filter-color"); }
		
		&:before {
			background-color: map-get($theme-values, "background-color");
			border: $btn-border-width solid map-get($theme-values, "border-color");
		}

		&.btn-sm:before {border-width: $btn-border-width-sm;}

		&.disabled,
		&:disabled {
			opacity: $btn-disabled-opacity;
			color: $font-color;
			background-color: map-get($theme-values, "background-color-disabled");
			border: $btn-border-width solid map-get($theme-values, "border-color-disabled");
		
			&.btn-sm { border-width: $btn-border-width-sm; }

			&.alt-opacity {
				opacity: 1;
				color: rgba($font-color, $btn-disabled-opacity);
				background-color: map-get($theme-values, "background-color-disabled");
				border-color: map-get($theme-values, "border-color-disabled-alt");

				.btn-icon {
					filter: map-get($theme-values, "filter-color");
					opacity: $btn-disabled-opacity;
				}
			}
		}

		&:not(:disabled):not(.disabled) {
			//hover state
			&:hover {
				@include btn-hover-secondary ($theme, $btn-themes: $btn-themes);
			}

			//active and focus state
			&:active,
			&.active,
			&:focus,
			&.focus,
			&:active:focus,
			&:hover:active,
			&:hover:focus {
				color: map-get($theme-values, "font-color-hover");
				box-shadow: $shadow-focus;
				outline: none;

				.btn-icon { filter: map-get($theme-values, "filter-color-hover");}

				&:before {
					background-color: map-get($theme-values, "background-color-focus");
					border: $btn-border-width solid map-get($theme-values, "border-color-focus");
				}
			}
		}
	}
}


/*
 HOVER COLORS
 This is a mixin so its possible to call them from other component affecting hover state of buttons (cards for example)
*/

//Primary hover colors
@mixin btn-hover-primary ($theme, $btn-themes: $btn-themes) {
	$theme-values: map-get($btn-themes, $theme);
	$shadow-hover: map-get($theme-values, "shadow-hover1"), map-get($theme-values, "shadow-hover2");
	$font-color: map-get($theme-values, "font-color");

	color: $font-color;
	background-color: map-get($theme-values, "background-color-hover");
	box-shadow: $shadow-hover;
	outline: none;

	.btn-icon { filter: map-get($theme-values, "filter-color"); }

	&:before { 
		background-color: map-get($theme-values, "background-color-hover");
	}
}


//Secondary hover colors
@mixin btn-hover-secondary ($theme, $inverse:false, $btn-themes: $btn-themes) {
	$theme-values: map-get($btn-themes, $theme);
	$shadow-hover: map-get($theme-values, "shadow-hover1"), map-get($theme-values, "shadow-hover2");

	background-color: transparent;
	border-color: transparent;

	@if $inverse == true {
		color: $blue-dark;
		box-shadow: none;

		.btn-icon { filter: $filter-blue-dark;}

		&:before {
			background-color: rgba($white, .88);
			border-color: rgba($white, .88);
		}
	}
	
	@else {
		color: map-get($theme-values, "font-color-hover");
		box-shadow: $shadow-hover;
		outline: none;

		.btn-icon { filter: map-get($theme-values, "filter-color-hover");}

		&:before {
			background-color: map-get($theme-values, "background-color-hover");
			border: $btn-border-width solid map-get($theme-values, "border-color-hover");
		}
	}
}


//Button Link hover colors
@mixin btn-link-hover-primary ($theme, $inverse:false, $btn-themes: $btn-themes) {
	$theme-values: map-get($btn-themes, $theme);
	text-decoration: none;
	box-shadow: none;
	outline: none;

	@if $inverse == true {
		color: $white;
	}
	
	@else {
		color: map-get($theme-values, "font-color");
	}
}

//Button Link Secondary hover colors
@mixin btn-link-hover-secondary ($theme, $inverse:false, $btn-themes: $btn-themes) {
	$theme-values: map-get($btn-themes, $theme);
	opacity: 1;
	box-shadow: none;
	outline: none;

	@if $inverse == true {
		color: $white;

		span { border-bottom-color: $white; }
	}
	
	@else {
		color: map-get($theme-values, "font-color");

		span { border-bottom-color: map-get($theme-values, "font-color"); }
	}
}