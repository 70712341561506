@mixin button-circle-primary ($theme) {
	$theme-values: map-get($btn-themes, $theme);
	$shadow-normal: map-get($theme-values, "circle-shadow-normal1"), map-get($theme-values, "circle-shadow-normal2");
	$shadow-hover: map-get($theme-values, "circle-shadow-hover1"), map-get($theme-values, "circle-shadow-hover2");
	$shadow-focus: map-get($theme-values, "circle-shadow-focus1"), map-get($theme-values, "circle-shadow-focus2");
	$font-color: map-get($theme-values, "font-color");


	color: $font-color;
	background-color: map-get($theme-values, "background-color");
	box-shadow: $shadow-normal;

	img,
	.icon { filter: map-get($theme-values, "filter-color"); }
	
	&:before {
		background-color: map-get($theme-values, "background-color");
		border-width: 0;
	}

	// Disabled comes first so active can properly restyle
	&.disabled,
	&:disabled {
		opacity: $btn-disabled-opacity;
		color: $font-color;
		background-color: map-get($theme-values, "background-color-disabled");
		box-shadow: none;

		// on inverse only alt opacity is different
		// must come first so .light override this
		.inverse &.alt-opacity {
			color: map-get($theme-values, "font-color-disabled-alt-inverse");
			background-color: map-get($theme-values, "background-color-disabled-alt-inverse");

			img,
			.icon { filter: map-get($theme-values, "filter-color-disabled-alt-inverse"); }
		}

		&.alt-opacity,
		.light &.alt-opacity {
			opacity: 1;
			color: map-get($theme-values, "font-color-disabled-alt");
			background-color: map-get($theme-values, "background-color-disabled-alt");

			img,
			.icon { filter: map-get($theme-values, "filter-color-disabled-alt"); }
			
		}
	}

	// Mouse Actions
	&:not(:disabled):not(.disabled) {
		&:hover {
			color: $font-color;
			background-color: map-get($theme-values, "background-color-hover");
			box-shadow: $shadow-hover;
			outline: none;

			img,
			.icon { filter: map-get($theme-values, "filter-color"); }
		}

		&:active,
		&.active,
		&:focus,
		&.focus,
		&:active:focus,
		&:hover:active,
		&:hover:focus {
			color: $font-color;
			box-shadow: $shadow-focus;
			outline: none;
			background-color: map-get($theme-values, "background-color-focus");
		}
	}
}


@mixin button-circle-secondary ($theme) {
	$theme-values: map-get($btn-themes, $theme);
	$shadow-normal: map-get($theme-values, "circle-shadow-normal1"), map-get($theme-values, "circle-shadow-normal2");
	$shadow-hover: map-get($theme-values, "circle-shadow-hover1"), map-get($theme-values, "circle-shadow-hover2");
	$shadow-focus: map-get($theme-values, "circle-shadow-focus1"), map-get($theme-values, "circle-shadow-focus2");
	$font-color: map-get($theme-values, "font-color");

	// inverse outlined color are same for all themes
	// must come first so .light is overriden
	.inverse & {
		color: $white;
		background-color: transparent;
		border: $btn-circle-border-width solid $white;
		box-shadow: none;

		img,
		.icon { filter: $filter-white;}

		&.disabled,
		&:disabled {
			opacity: $btn-disabled-opacity;
			color: $white;
			background-color: transparent;
			border-color: $white;
	
			&.alt-opacity {
				opacity: 1;
				color: #56656F;
				background-color: $blue-dark;
				border-color: #56656F;
	
				img,
				.icon {
					filter: $filter-white;
					opacity: $btn-disabled-opacity;
				}
			}
		}

		&:not(:disabled):not(.disabled) {
			&:hover {
				color: $blue-dark;
				background-color: rgba($white, .88);
				border-color: rgba($white, .88);
				box-shadow: none;

				img,
				.icon { filter: $filter-blue-dark;}
			}

			&:active,
			&.active,
			&:focus,
			&.focus,
			&:active:focus,
			&:hover:active,
			&:hover:focus {
				color: $blue-dark;
				background-color: $white;
				border-color: $white;
				box-shadow: none;

				img,
				.icon { filter: $filter-blue-dark;}
			}
		}
	}

	// basic and light on inverse styles
	&,
	.light & {
		color: $font-color;
		background-color: map-get($theme-values, "background-color");
		border: $btn-circle-border-width solid map-get($theme-values, "border-color");
		box-shadow: $shadow-normal;

		img,
		.icon { filter: map-get($theme-values, "filter-color"); }

		&.disabled,
		&:disabled {
			opacity: $btn-disabled-opacity;
			color: $font-color;
			background-color: map-get($theme-values, "background-color-disabled");
			border: $btn-circle-border-width solid map-get($theme-values, "border-color-disabled");
			box-shadow: none;
		
			&.alt-opacity {
				opacity: 1;
				color: rgba($font-color, $btn-disabled-opacity);
				background-color: map-get($theme-values, "background-color-disabled");
				border-color: map-get($theme-values, "border-color-disabled-alt");

				img,
				.icon {
					filter: map-get($theme-values, "filter-color");
					opacity: $btn-disabled-opacity;
				}
			}
		}

		&:not(:disabled):not(.disabled) {
			&:hover {
				color: map-get($theme-values, "font-color-hover");
				box-shadow: $shadow-hover;
				outline: none;
				background-color: map-get($theme-values, "background-color-hover");
				border: $btn-circle-border-width solid map-get($theme-values, "border-color-hover");

				img,
				.icon { filter: map-get($theme-values, "filter-color-hover");}
			}

			&:active,
			&.active,
			&:focus,
			&.focus,
			&:active:focus,
			&:hover:active,
			&:hover:focus {
				color: map-get($theme-values, "font-color-hover");
				box-shadow: $shadow-focus;
				outline: none;
				background-color: map-get($theme-values, "background-color-focus");
				border: $btn-circle-border-width solid map-get($theme-values, "border-color-focus");

				img,
				.icon { filter: map-get($theme-values, "filter-color-hover");}
			}
		}
	}
}
