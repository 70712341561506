@import "../../variables";
@import "../../mixins";
@import "../../../bootstrap/functions";
@import "../../../bootstrap/mixins";

@import "../../smb/components/_smb-contact-box.scss";

// SECTIONS
.section {
	padding-top: $base-unit * 8;
	padding-bottom: $base-unit * 8;
}

.hero-subheadline a {
	text-decoration: dotted underline 1px;
}

// VIDEO STORY
#video-story,
#section-cont-img {
	.container {
		background-image: url("../../../../../i/v2/components/bg/bg-deep-blade.svg");
	}
}

.section--advanced {
	background: $blue-dark;
	background: linear-gradient(
		0deg,
		$blue-dark 0%,
		$blue-dark 50%,
		$blue-deeper 50%,
		$blue-deeper 100%
	);
}

.section--sysreq {
	background: $blue-deeper;
	background: linear-gradient(
		0deg,
		$blue-deeper 0%,
		$blue-deeper 50%,
		$blue-dark 50%,
		$blue-dark 100%
	);

	.system-req {
		border-top-left-radius: 0;

		@include media-breakpoint-up(xl) {
			.system-req-body {
				padding-left: $base-unit * 9;
				padding-right: $base-unit * 9;
			}
		}
	}
}

.media .media-image .btn-wrapper .media-img-button img,
.media .media-body-image .btn-wrapper .media-img-button img {
	height: 100px;
	transition: transform 200ms;
	&:hover {
		transform: scale(1.1);
	}
}
