/*
ELEVATION STYLES
Thanks to mixins these styles may be applied on any class
*/


/*
Elevation shadow
Style has 3 sizes - default size is large ($elevation-shadow-l). To use other sizes call mixin with $elevation-shadow-m or $elevation-shadow-s variables.
*/

@mixin elevation-box-shadow($alpha: 1, $size: "l", $defaultBgColor: true) {
	
	$shadow-size: map-get($map: $elevation-shadow-sizes, $key: ($size) );
	$shadow-alpha: map-get($map: $elevation-shadow-alphas, $key: ($size+$alpha) );

	.inverse & {
		box-shadow: $shadow-size rgba($elevation-box-shadow-color-inverse, $shadow-alpha) !important;
		@if ($defaultBgColor == true) { background-color: $blue-deep; }
	}

	&,
	.light & {
		box-shadow: $shadow-size rgba($elevation-box-shadow-color, $shadow-alpha) !important;
		@if ($defaultBgColor == true) { background-color: $white; }
	}

	&.elevation-orange,
	.light &.elevation-orange {
		box-shadow: $shadow-size rgba(#583D25, $shadow-alpha) !important;
		@if ($defaultBgColor == true) { background-color: $white; }
	}
}