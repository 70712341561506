@import "../../../bootstrap/functions";
@import "../../variables";
@import "../../mixins";
@import "../../../bootstrap/mixins";

.smb-contact-box {
    position: relative;
    width: $base-unit * 27;
    max-width: 100%;
    padding: $base-unit*1.75 $base-unit*1.5;
    background-color: $white;
    border: 1px solid $gray08;
    border-radius: $base-unit;
    transition: transform 350ms ease-out;

    &--has-badge {
        padding-top: $base-unit * 2.25;
    }

    &--sticky {
        position: fixed;
        z-index: 999; // Lower than 1000 -- so it's under download popup, region selector and overlay
        bottom: 0;
        right: $base-unit * 7.5;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transform: translateY(200%);

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &--visible {
        transform: translateY(0%);
    }

    &__badge {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }

    &__content {
        display: flex;
        flex: 50% 0 0;
        flex-direction: row;
        align-items: center;
    }

    &__left, &__right {
        flex: 50% 0 0;
    }

    &__right {
        display: flex;
        justify-content: flex-end;
    }

    &__title {
        margin-bottom: $base-unit * 0.25;
    }

    &__text {
        font-size: $body-sm-font-size;
        line-height: $body-md-line-height;
        color: $gray03;
    }
}
